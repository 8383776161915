

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class InternetConnection extends Vue {
  @Prop({ type: Boolean, required: false }) loading!: boolean;
}
